*,
*::after,
*::before {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

body {
    background: #F5F0EC;
}

.primary {
    color: #3282FF;
}

.sec {
    color: #000023;
}

.white {
    color: #fff;
}

.mars {
    font-family: 'Mars Condensed';
}

.obj {
    font-family: 'Object Sans';
}

.flex {
    display: flex;
}

.flex-sb {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.direction-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.w-50 {
    width: 50%;
}

.header {
    position: absolute;
    top: 24px;
    left: 16px;
}

.logo {
    width: 178px;
    height: 83px;
}

.title {
    font-size: 78.5px;
    font-weight: 400;
    line-height: 72px;
    padding: 24px;
}

@media screen and (max-width: 768px) {
    .title {
        padding: 24px 14px;
        font-size: 36px;
        line-height: 38px;
    }
}

/* card style */
.card-wrapper {
    margin: 0 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

.card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 1);
    position: relative;
}

.card-details {
    margin-bottom: 16px;
    background-image: url('../media/cardBG.png');
    height: 196px;
    padding: 24px;
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
}

input[id="checkbox"]+label {
    display: none;
}

.details-wrapper {
    padding: 24px;
}

.card-title {
    font-size: 18.3px;
    line-height: 31px;
    font-weight: 600;
    padding: 6px 0;
}

.company-name {
    padding-bottom: 12px;
    padding-top: 36px;
}

.company-logo {
    width: 100%;
}

.company-link {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    color: #ffffff;
    padding-bottom: 3px;
    line-height: 31px;
    padding-right: 3px;
    border-bottom: 1px solid #ffffff;
}

.website-wrapper {
    margin-bottom: 8px;
}

.card-details-sector-company {
    align-items: end;
    margin-top: 12px;
}

.btn {
    border: none;
    background: none;
    cursor: pointer;
    width: auto;
}

.about-company {
    font-weight: 400;
    font-size: 12.5px;
    line-height: 15.6px;
    margin-bottom: 8px;
}

@media screen and (max-width: 1240px) {
    .card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1080px) {
    .card-wrapper {
        margin: 0 26px;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .card-wrapper {
        margin: 0 26px;
        grid-template-columns: repeat(2, 1fr);
    }

    .card-title {
        font-size: 20px;
    }
}

@media screen and (max-width: 528px) {
    .card-wrapper {
        grid-template-columns: 1fr;
        max-width: 417.5px;
        margin: 0 14px;
    }

    .company-link {
        display: block;
        font-weight: 700;
        font-size: 12px;
        width: auto;
    }

    .about-company {
        font-size: 13px;
        line-height: 159%;
        margin-left: 0;
    }
}

/* footer */

.footer {
    padding: 14px 68px;
    background: #000023;
}

.footer-title {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 26px;
}

.copyright {
    color: #052E75;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
}

.card-item-end {
    position: absolute;
    right: 25px;
    top: 25px;
}

.company-logo-wrapper {
    width: 33%;
    height: 75px;
    margin-top: 32px;
}

.next-btn {
    width: 101px;
    height: 73px;
    color: rgba(0, 0, 35, 0.25);
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 26px;
    background: transparent;
    margin: 25px 0 77px 66px;
    cursor: pointer;
}

/* login page */

.active {
    color: #000023;
    border: 1px solid #000023;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 14px 18px;
    }

    .copyright {
        font-size: 12px;
    }

    .footer-title {
        font-size: 24px;
    }

    .company-logo-wrapper {
        height: auto;
    }

    .next-btn {
        width: 70px;
        height: 42px;
        font-size: 20px;
        margin: 25px 16px;
    }
}

select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #000;
    outline: none;
}

input:checked::before {
    content: '\2713';
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Arial', sans-serif;
}

.selectedCard {
    background: url(../media/Rectangle.png), lightgray 0px 0px / 100% 100% no-repeat;
}

.select {
    padding: 0 12.25px;
}

.disabled {
    cursor: not-allowed;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 35, 0.90);
    background-blend-mode: multiply;
    justify-content: center;
    z-index: 99;
}

.modal-wrapper {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    width: 332px;
    flex-direction: column;
    justify-content: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.modal-open {
    overflow: hidden;
}

.modal-heading {
    text-align: center;
    font-size: 76px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 50px;
    margin-top: 12px;
}

.modal-content {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.6px;
    margin-bottom: 25px;
}

.modal-btn {
    width: 296px;
    height: 74px;
    flex-shrink: 0;
    border: 2px solid #FFF;
    font-size: 30px;
    font-weight: 400;
    line-height: 26px;
    justify-content: center;
}

.fix-next-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    margin: 14px;
    z-index: 9;
    display: none;
}

.fix-back-btn {
    right: 150px;
}

.read-more {
    border: none;
    background: transparent;
    padding: 8px 8px 8px 0px;
    display: block;
    cursor: pointer;
    font-family: 'Object Sans';
}

.card-subheading {
    font-size: 12px;
}

.capital {
    text-transform: uppercase;
}

.modal-for-company {
    width: 100%;
    padding: 24px;
    max-width: 700px;
}

.modal-close {
    font-size: 64px;
    position: absolute;
    top: 30px;
    right: 50px;
}

.modal-company-content {
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
}

.option-btn-wrapper {
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    margin: 24px;
    align-items: center;
}

.options-btn {
    margin-right: 14px;
    font-size: 18px;
}

.option-active {
    border-bottom: 2px solid #3282FF;
}

@media screen and (max-width: 768px) {
    .modal-close {
        font-size: 36px;
        top: 20px;
        right: 20px;
    }

    .modal-company-content {
        font-size: 12px;
        line-height: 1.2;
    }

    .modal-heading {
        font-size: 36px;
        margin: 12px;
    }

    .options-btn {
        margin-right: 8px;
        font-size: 15px;
    }

    .modal-for-company {
        padding: 10px;
    }

    .modal-btn {
        width: 150px;
        height: 50px;
        font-size: 16px;
    }
}

.last-item {
    margin-top: auto;
}

.mobile {
    display: none;
}

.selectedSubPara {
    padding: 24px;
    /* max-width: 1200px; */
    width: 100%;
    margin-top: 120px;
}

.selectedCompaniesWrapper {
    margin-top: 120px;
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .selectedSubPara {
        padding: 0 16px 16px;
    }
}

.select-option {
    width: 200px;
    height: 30px;
    padding: 5px 12px;
    font-size: 14px;
}

.popup-wrapper {
    max-width: 600px;
    width: 100%;
}

.popup-content {
    font-size: 20px;
}


.formContainer {
    margin: 0 auto;
    display: flex;
    color: #ffffff;
}

.textWrapper,
.outerFormWrapper {
    width: 50%;
    padding: 135px 50px 60px;
    min-height: calc(100vh - 54px);
}

.subheading {
    color: #00236e;
}

.textWrapper {
    background: url('../media/bg100.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.outerFormWrapper {
    background-color: #01226d;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formHeading {
    font-size: 5.5vw;
    width: 60%;
    line-height: 1;
    font-family: 'Mars Condensed';
    font-weight: 400;
    text-transform: uppercase;
}

.formSubheading {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    font-family: 'Object Sans';
    padding-bottom: 20px;
    color: #3283ff;
}

.input {
    width: 100%;
    padding: 14px 0;
    height: 38.25px;
    font-size: 18px;
    margin-bottom: 14px;
    outline: none;
    border: none;
    font-family: 'Object Sans';
    background: transparent;
    border-bottom: 1.5px solid #3283ff;
    color: #ffffff;
}

.input::placeholder {
    color: white;
    opacity: 0.8;
    font-weight: 100;
    font-size: 16;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill {
    box-shadow: 0 0 0 1000px transparent inset !important;
    -moz-text-fill-color: #ffffff !important;
}

.input::selection {
    background: transparent;
    color: #ffffff;
}

.outerWrapper {
    width: 75%;
    margin: 0 auto;
}

.btn {
    width: 30%;
    height: 46.25px;
    font-size: 20px;
    margin-bottom: 8px;
    background: #3283ff;
    font-size: 30px;
    line-height: 36.25px;
    color: #ffffff;
    outline: none;
    border: none;
    font-family: 'Mars Condensed';
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.para {
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    font-family: 'Object Sans';
    padding: 20px 0;
    font-weight: 400;
    line-height: 26px;
}

@media screen and (max-width: 1024px) {
    .formContainer {
        width: 100%;
    }

    .textWrapper {
        width: 50%;
    }

    .outerWrapper {
        width: 80%;
    }

    .formHeading {
        font-size: 56px;
        line-height: 1.2;
    }

    .para {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .formContainer {
        flex-direction: column;
    }

    .textWrapper,
    .outerFormWrapper {
        width: 100%;
        padding: 25px 50px;
    }

    .outerFormWrapper {
        display: block;
        min-height: auto;
    }

    .textWrapper {
        padding-top: 140px;
        min-height: auto;
        padding-bottom: 20vh;
    }


    .outerWrapper {
        width: 95%;
        margin: 0 auto;
    }

    .formHeading {
        font-size: 48px;
        width: 100%;
    }

    .formSubheading,
    .para {
        font-size: 16px;
        text-align: center;
    }

    .formSubheading {
        font-weight: 900;
    }
}

@media screen and (max-width: 540px) {
    .formContainer {
        width: 100%;
    }

    .outerFormWrapper {
        padding: 24px;
    }
}

.w-60 {
    width: 60%;
}
